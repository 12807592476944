/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAuthCodeRequestModel } from "models/Auth/IAuthCodeRequestModel";
import { ICoinsPaymentRequestModel } from "models/CryptoCoins/ICoinsPaymentRequestModel";
import { ICoinsPaymentResponseModel } from "models/CryptoCoins/ICoinsPaymentResponseModel";
import { IConfirmPaymentRequestModel } from "providers/PaymentProvider/models";

import { MediaType, ScreenType } from "../../../enums";
import { AuthorizationHelper, StorageHelper } from "../../../helpers";
import {
  EpgDay,
  IAssetAgeRestrictionModel,
  IAssetContentModel,
  IAssetContentTranscodeRequest,
  IAssetImageModel,
  IAssetInAssetModel,
  IAssetInAssetSearchResponseModel,
  IAssetListModel,
  IAssetModel,
  IAssetPriceListModel,
  IAssetPriceModel,
  IAssetPriceSearchFilterModel,
  IAssetPurchasePeriodTypeModel,
  IAssetSearchFilterModel,
  IAssetsInAssetSearchFilterModel,
  IAuthRequestModel,
  IAuthResponseModel,
  IAuthVerifyLoginModel,
  ICatchupInsertModel,
  ICatchupInsertResponseModel,
  IChangePasswordModel,
  IConfigurationBrandingModel,
  IConfigurationModel,
  IConfigurationTranslationsModel,
  IConfirmAccountWithPasswordModel,
  ICryptoCoinPriceListModel,
  ICurrencyModel,
  Identifier,
  IDownloadSource,
  IForgotPasswordModel,
  IInsertAssetRequestModel,
  IInsertAssetResponseModel,
  IInviteManyUsersModel,
  ILoginCodeModel,
  IMediaCategoryListModel,
  IMediaListModel,
  IMediaListOptionsModel,
  IMediaModel,
  IMediaOptionsModel,
  IMediaPaymentRequestModel,
  IMediaPaymentResponseModel,
  IMediaPlaybackProgressModel,
  IMediaPlayInfoModel,
  IMediaPlayInfoOptionsModel,
  IMediaPurchaseOfferModel,
  IMediaSearchFilterModel,
  IMediaSearchMediaInMediaFilterModel,
  IMediaSearchStateModel,
  IMediaStatisticsOptionsModel,
  IPaymentListModel,
  IPaymentModel,
  IPaymentOptionsModel,
  IPaymentSearchFilterModel,
  IPaymentStatusModel,
  IPaymentTypeMappingAndOptionsModel,
  IRegisterConfirmEmailModel,
  IRegisterRequestEmailModel,
  IRemoveManyUsersModel,
  IResendConfirmationByUserModel,
  IResetForgotPasswordModel,
  IResetPasswordModel,
  IScreenModel,
  IUserAssetPropertiesModel,
  IUserAssetPurchasesListModel,
  IUserAssetPurchasesSearchFilterModel,
  IUserConsentModel,
  IUserDeleteAccountRequestModel,
  IUserDeviceModel,
  IUserInAssetModel,
  IUserInAssetRoleModel,
  IUserInfoModel,
  IUserModel,
  IUserProductModel,
  IUserPurchasesAggregatedModel,
  IUserRatingCategory,
  IUserRatingModel,
  IUserRequestOptionsModel,
  IUserSettingsModel,
  IUsersInAssetListModel,
  IUsersInAssetSearchFilterModel,
  IUsersListModel,
  IUsersSearchFilterModel,
  IUserWalletModel,
} from "../../../models";
import {
  AuthService,
  HttpFactory,
  StorageKey,
  StorageManager,
} from "../../../services";
import { DownloadAssetsService } from "../../../services/Download";
import { AuthStore, dispatch, OfflineStore } from "../../../store";
import { IDataProvider } from "../IDataProvider";

import { ModelsMapperHelper } from "./helpers/modelsMapperHelper";
import {
  AssetInAssetService,
  AssetPriceService,
  AssetPurchasePeriodTypeService,
  AssetService,
  ConfigurationService,
  CurrenciesService,
  HttpRequestFulfilledInterceptor,
  HttpRequestRejectedInterceptor,
  HttpResponseFulfilledInterceptor,
  HttpResponseRejectedInterceptor,
  MediaService,
  PaymentsService,
  RegisterService,
  UserAssetPurchasesService,
  UserConsentsService,
  UserMediaPropertiesService,
  UserPlatformRatingService,
  UserService,
  UserSettingsService,
} from "./services";
import { CryptoCoinPriceListsService } from "./services/CryptoCoinPriceListsService";
import { CryptoCoinsService } from "./services/CryptoCoinsService";
import { UserInAssetService } from "./services/User/UserInAssetService";

export class InternalDataProvider implements IDataProvider {
  currenciesService = new CurrenciesService();
  configurationService = new ConfigurationService();
  authService = new AuthService();
  registerService = new RegisterService();
  mediaService = new MediaService();
  assetService = new AssetService();
  assetInAssetService = new AssetInAssetService();
  assetPriceService = new AssetPriceService();
  assetPurchasePeriodTypeService = new AssetPurchasePeriodTypeService();
  paymentsService = new PaymentsService();
  userMediaPropertiesService = new UserMediaPropertiesService();
  userAssetPurchasesService = new UserAssetPurchasesService();
  cryptoCoinPriceListsService = new CryptoCoinPriceListsService();
  cryptoCoinsService = new CryptoCoinsService();
  userConsentsService = new UserConsentsService();
  userService = new UserService();
  userInAssetService = new UserInAssetService();
  userSettingsService = new UserSettingsService();
  userPlatformRatingService = new UserPlatformRatingService();
  httpFactory = HttpFactory.getInstance();
  httpClient = this.httpFactory.getHttpClient();

  public _defaultTranslations: IConfigurationTranslationsModel = {
    pl: {
      APP_CLOSE_DIALOG__TITLE: "Zamknąć aplikację?",
      APP_CLOSE_DIALOG__MESSAGE: "Czy chcesz zamknąć aplikację?",
      APP_GLOBAL_ERROR_DIALOG__TITLE: "Błąd",
      COMMON__INTERNET_CONNECTION_ERROR_TITLE: "Brak połączenia.",
      COMMON__INTERNET_CONNECTION_ERROR_MESSAGE:
        "Brak połączenia z Internetem, połącz ponownie.",
      CLOSE__APP: "Zamknij aplikację",
      INCORRECT_FILE_TYPE: "Niewłaściwy typ pliku",
      LINK__BUTTON: "Link",
      LINK__ERROR_SHORT_MESSAGE:
        "Wprowadzony kod jest za krótki. Proszę wypełnić wszystkie pola",
      LINK__ERROR_MESSAGE:
        "Wprowadzony kod jest niepoprawny. Proszę spróbować jeszcze raz",
      LINK__SUCCESS_MESSAGE: "Twoje konto zostało podłączone",
      MENU_SEARCH: "Szukaj",
      MENU_HOME: "Strona główna",
      MENU_TRANSMISSIONS: "Transmisje",
      MENU_RETRANSMISSIONS: "Retransmisje",
      MENU_CATEGORIES: "Kategorie",
      MENU_MY_LIST: "Moja lista",
      MENU_SETTINGS: "Ustawienia",
      MEDIA_PERSON_ROLE_DIRECTOR_LABEL: "Reżyser:",
      MEDIA_PERSON_ROLE_DISPLAY_NAME: "Komentarz",
      MEDIA_PERSON_ROLE_WRITER_LABEL: "Scenografia:",
      MEDIA_PERSON_ROLE_CAST_LABEL: "Obsada:",
      MEDIA_PERSON_ROLE_COMMENTATOR_LABEL: "Komentarz:",
      MEDIA_LIST_ITEM_ONGOING_MARKER: "Trwa",
      MEDIA_LIST_ITEM_YESTERDAY_MARKER: "Wczoraj",
      MESSAGE__INCORRECT_OLD_PASSWORD: "Nieprawidłowe stare hasło",
      PHONE_COUNTRY_CODE_VALIDATION_MESSAGE:
        "Brak numeru kierunkowego ze znakiem '+' (np. +48)",
      PHONE_LENGTH_VALIDATION_MESSAGE: "Maksymalnie 15 znaków",
      CATEGORY_LIST_TITLE_DEFAULT: "KATEGORIA LISTA (DOMYŚLNA)",
      SEARCH__HEADER: "WYNIKI WYSZUKIWANIA DLA:",
      SEARCH__RECOMMENDATION_LIST: "POLECANE",
      SEARCH__INPUT_LABEL: "Wyszukaj",
      SEARCH__NO_RESULTS_MESSAGE:
        "Nie znaleźliśmy żadnych wyników dla Twojego wyszukiwania",
      MY_LIST__NO_RESULTS_TITLE: "Lista jest pusta",
      MY_LIST__NO_RESULTS_MESSAGE:
        "Nie dodałeś jeszcze żadnych materiałów do Twojej listy.",
      RECOMMENDATIONS_LIST__TITLE: "Zobacz także",
      MOVIE_DETAILS__START_TRANSMISSION_TITLE: "Początek transmisji:",
      PLAYER_SETTINGS__MAIN_MENU_TITLE: "Ustawienia",
      PLAYER_SETTINGS__MENU_VIDEO_QUALITY_TITLE: "Jakość wideo",
      PLAYER_SETTINGS__MENU_SUBTITLES_TITLE: "Język napisów",
      PLAYER_SETTINGS__MENU_SUBTITLES_OPTION_NO_SUBTITLES: "Brak napisów",
      PLAYER_SETTINGS__MENU_SOUNDTRACK_TITLE: "Ścieżka dźwiękowa",
      PLAYER_SETTINGS__MENU_SOUNDTRACK_OPTION_NO_SOUNDTRACK:
        "Brak ścieżki dźwiękowej",
      PLAYER_SETTINGS__MENU_VIDEO_QUALITY_OPTION_AUTO: "Auto",
      PLAYER_STATISTICS__PENALTY_SHOOTOUT_TITLE: "RZUTY KARNE",
      PLAYER_STATISTICS__EXTRA_TIME_TITLE: "DOGRYWKA",
      PLAYER_STATISTICS__MATCH_STATUS_INPROGRESS: "TRWA",
      PLAYER_STATISTICS__MATCH_STATUS_FINISHED: "ZAKOŃCZONY",
      PLAYER_STATISTICS__MATCH_STATUS_INTERRUPTED: "PRZERWANY",
      PLAYER_STATISTICS__MATCH_STATUS_CANCELLED: "ODWOŁANY",
      COMMON__YES: "Tak",
      COMMON__NO: "Nie",
      COMMON__OK: "OK",
      COMMON__WATCH: "Ogladaj",
      COMMON__WATCH_NEXT: "Oglądaj dalej",
      COMMON__ADD_TO_MY_LIST: "Dodaj do mojej listy",
      COMMON__REMOVE_FROM_MY_LIST: "Usuń z mojej listy",
      COMMON__NO_INFORMATION: "Brak informacji",
      CONNECTION_ERROR_INFO_TITLE:
        "Wystąpił problem z załadowaniem zawartości.",
      CONNECTION_ERROR_INFO_BUTTON: "Ponów próbę",
      HELP_SETTINGS_SCREEN__TITLE: "Problemy z aplikacją?",
      PRIVACY_POLICY_SETTINGS_SCREEN__TITLE: "Polityka prywatności",
      MY_CONSENTS_SETTINGS_SCREEN__TITLE: "Moje zgody",
      MY_CONSENTS_BUTTON__ACCEPTED: "Potwierdzono",
      MY_CONSENTS_BUTTON__TO_ACCEPT: "Potwierdź",
      REGULATIONS_SETTINGS_SCREEN__TITLE: "Regulamin",
      ROLE: "Rola",
      SETTINGS_SCREEN__TITLE: "Ustawienia",
      SETTINGS_SCREEN__OPTION_ABOUT_TITLE: "O nas",
      SETTINGS_SCREEN__OPTION_HELP_TITLE: "Problemy z aplikacją?",
      SETTINGS_SCREEN__OPTION_MY_CONSENTS_TITLE: "Moje zgody",
      SETTINGS_SCREEN__OPTION_REGULATIONS_TITLE: "Regulamin",
      SETTINGS_SCREEN__OPTION_PRIVACY_POLICY_TITLE: "Polityka prywatności",
      EPISODES_NOT_FOUND: "Brak odcinków",
      LOGIN_LABEL: "Zaloguj",
      LOGIN_CODE_INFO_LINK: `${process.env.REACT_APP_TENANT_ORIGIN_URL}`,
      LOGIN_CODE_INFO: "Przejdź do {{LINK}}/link żeby zalogować",
      USER_BUY_ON_MOBILE_OR_WEB:
        "Zaloguj się w aplikacji mobilnej lub webowej by wykonać zakup",
      USER_IN_ASSET_EVENT_NAME: "Nazwa",
      TOKEN_VALID_INFO: "Token wygaśnie za ",
      BUTTON_REFRESH_TOKEN: "Odśwież",
      VALIDATION__AVAILABILITY_STARTS_AT_START_DATE:
        "Data dostępu musi byc ustawiona przed datą rozpoczęcia",
      VALIDATION__CATCHUP_DATE_SET_BEFORE_EVENT_ENDS:
        "Czas dostępności wydarzenia, to co najmniej 2 godziny po zakończeniu eventu",
      VALIDATION__CATCHUP_DATE_SET_IN_THE_PAST:
        "Dostępność wydarzenia nie może mieć daty wstecz",
      VALIDATION__START_DATE_AFTER_END_DATE:
        "Wydarzenie nie może się odbywać po jego zakończeniu",
      VALIDATION__START_DATE_IS_EQUAL_TO_END_DATE:
        "Data rozpoczęcia wydarzenia nie może być taka sama jak data zakończenia",
      COMMON__EPG_NO_DATA: "Brak danych do wyświetlenia",
      MAINTENANCE_SCREEN__MAINTENANCE:
        "Przepraszamy, aktualnie strona jest niedostępna z powodu prac konserwatorskich",
      MAINTENANCE_SCREEN__BACK_SOON: "Niedługo będziemy z powrotem",
    },
    en: {
      LOGIN_INFO: "Go to {{LINK}}/link to login",
      LOGIN_INFO_LINK: `${process.env.REACT_APP_TENANT_ORIGIN_URL}`,
      LOGIN_LABEL: "Login",
      APP_CLOSE_DIALOG__TITLE: "Close application?",
      APP_CLOSE_DIALOG__MESSAGE: "Do you want to close the application?",
      APP_GLOBAL_ERROR_DIALOG__TITLE: "Error",
      CLOSE__APP: "Close Application",
      COMMON__INTERNET_CONNECTION_ERROR_TITLE: "No connection.",
      COMMON__INTERNET_CONNECTION_ERROR_MESSAGE:
        "No internet connection, please reconnect.",
      INCORRECT_FILE_TYPE: "Incorrect file type",
      LINK__BUTTON: "Link",
      LINK__ERROR_SHORT_MESSAGE:
        "The code you have entered is too short. Please fill up all inputs",
      LINK__ERROR_MESSAGE:
        "The code you have entered is incorrect. Please try again",
      LINK__SUCCESS_MESSAGE: "Your account has been linked",
      MENU_SEARCH: "Search",
      MENU_HOME: "Home",
      MENU_TRANSMISSIONS: "Broadcasts",
      MENU_RETRANSMISSIONS: "Retransmissions",
      MENU_CATEGORIES: "Categories",
      MENU_MY_LIST: "My list",
      MENU_SETTINGS: "Settings",
      MEDIA_PERSON_ROLE_DISPLAY_NAME: "Comment",
      MEDIA_PERSON_ROLE_DIRECTOR_LABEL: "Director:",
      MEDIA_PERSON_ROLE_WRITER_LABEL: "Writer:",
      MEDIA_PERSON_ROLE_CAST_LABEL: "Cast:",
      MEDIA_PERSON_ROLE_COMMENTATOR_LABEL: "Commentator:",
      MEDIA_LIST_ITEM_ONGOING_MARKER: "Ongoing",
      MEDIA_LIST_ITEM_YESTERDAY_MARKER: "Yesterday",
      MESSAGE__INCORRECT_OLD_PASSWORD: "Incorrect old password",
      PHONE_COUNTRY_CODE_VALIDATION_MESSAGE:
        "Country code with '+' sign is required (e.g. +48)",
      PHONE_LENGTH_VALIDATION_MESSAGE: "Maximum 15 characters",
      CATEGORY_LIST_TITLE_DEFAULT: "CATEGORY LIST (DEFAULT)",
      SEARCH__HEADER: "SEARCH RESULTS FOR:",
      SEARCH__RECOMMENDATION_LIST: "RECOMMENDED",
      SEARCH__INPUT_LABEL: "Search",
      SEARCH__NO_RESULTS_MESSAGE:
        "Sorry, we didn't find any results matching this search.",
      MY_LIST__NO_RESULTS_TITLE: "The list is empty",
      MY_LIST__NO_RESULTS_MESSAGE:
        "You haven't added any materials to your list yet.",
      RECOMMENDATIONS_LIST__TITLE: "See also",
      MOVIE_DETAILS__START_TRANSMISSION_TITLE: "Transmission start:",
      PLAYER_SETTINGS__MAIN_MENU_TITLE: "Settings",
      PLAYER_SETTINGS__MENU_VIDEO_QUALITY_TITLE: "Video quality",
      PLAYER_SETTINGS__MENU_VIDEO_QUALITY_OPTION_AUTO: "Auto",
      PLAYER_SETTINGS__MENU_SUBTITLES_TITLE: "Subtitle language",
      PLAYER_SETTINGS__MENU_SUBTITLES_OPTION_NO_SUBTITLES: "No subtitles",
      PLAYER_SETTINGS__MENU_SOUNDTRACK_TITLE: "Soundtrack",
      PLAYER_SETTINGS__MENU_SOUNDTRACK_OPTION_NO_SOUNDTRACK: "No soundtrack",
      PLAYER_STATISTICS__PENALTY_SHOOTOUT_TITLE: "RZUTY KARNE",
      PLAYER_STATISTICS__EXTRA_TIME_TITLE: "DOGRYWKA",
      PLAYER_STATISTICS__MATCH_STATUS_INPROGRESS: "TRWA",
      PLAYER_STATISTICS__MATCH_STATUS_FINISHED: "ZAKOŃCZONY",
      PLAYER_STATISTICS__MATCH_STATUS_INTERRUPTED: "PRZERWANY",
      PLAYER_STATISTICS__MATCH_STATUS_CANCELLED: "ODWOŁANY",
      COMMON__YES: "Yes",
      COMMON__NO: "No",
      COMMON__OK: "OK",
      COMMON__WATCH: "Watch",
      COMMON__ADD_TO_MY_LIST: "Add to my list",
      COMMON__REMOVE_FROM_MY_LIST: "Remove from my list",
      COMMON__NO_INFORMATION: "No information",
      CONNECTION_ERROR_INFO_TITLE: "There was a problem loading the content.",
      COMMON__WATCH_NEXT: "Watch next",
      CONNECTION_ERROR_INFO_BUTTON: "Try again",
      HELP_SETTINGS_SCREEN__TITLE: "Application problems?",
      PRIVACY_POLICY_SETTINGS_SCREEN__TITLE: "Privacy policy",
      MY_CONSENTS_SETTINGS_SCREEN__TITLE: "My consents",
      MY_CONSENTS_BUTTON__ACCEPTED: "Accepted",
      MY_CONSENTS_BUTTON__TO_ACCEPT: "Accept",
      REGULATIONS_SETTINGS_SCREEN__TITLE: "Regulations",
      ROLE: "Role",
      SETTINGS_SCREEN__TITLE: "Settings",
      SETTINGS_SCREEN__OPTION_ABOUT_TITLE: "About",
      SETTINGS_SCREEN__OPTION_HELP_TITLE: "Application problems?",
      SETTINGS_SCREEN__OPTION_MY_CONSENTS_TITLE: "My consents",
      SETTINGS_SCREEN__OPTION_REGULATIONS_TITLE: "Regulations",
      SETTINGS_SCREEN__OPTION_PRIVACY_POLICY_TITLE: "Privacy policy",
      EPISODES_NOT_FOUND: "Episodes not found",
      USER_BUY_ON_MOBILE_OR_WEB:
        "Please login on mobile or web to purchase a product",
      USER_IN_ASSET_EVENT_NAME: "Name",
      TOKEN_VALID_INFO: "Token is valid for ",
      BUTTON_REFRESH_TOKEN: "Refresh",
      VALIDATION__AVAILABILITY_STARTS_AT_START_DATE:
        "Availablity date must be set before start date",
      VALIDATION__CATCHUP_DATE_SET_BEFORE_EVENT_ENDS:
        "Start time should be set at least 2 hours after event ends",
      VALIDATION__CATCHUP_DATE_SET_IN_THE_PAST:
        "Event availability cannot be set in the past",
      VALIDATION__START_DATE_AFTER_END_DATE:
        "The event cannot be set after end date",
      VALIDATION__START_DATE_IS_EQUAL_TO_END_DATE:
        "The event start date cannot be the same as end date",
      COMMON__EPG_NO_DATA: "No data to display",
      MAINTENANCE_SCREEN__MAINTENANCE:
        "Sorry, we're currently down for maintenance",
      MAINTENANCE_SCREEN__BACK_SOON: "We'll be back soon",
    },
  };

  initHttpFactory() {
    this.httpFactory.addRequestInterceptor(
      "HttpRequestInterceptor",
      HttpRequestFulfilledInterceptor,
      HttpRequestRejectedInterceptor,
    );
    this.httpFactory.addResponseInterceptor(
      "HttpResponseInterceptor",
      HttpResponseFulfilledInterceptor,
      HttpResponseRejectedInterceptor,
    );
  }

  async initSession() {
    const session = await StorageManager.getValue(StorageKey.session);

    if (!session) {
      dispatch(AuthStore.Actions.signInAnonymous());
    }
  }

  getDefaultBranding(): IConfigurationBrandingModel | undefined {
    return undefined;
  }

  getDefaultTranslations(): IConfigurationTranslationsModel | undefined {
    return this._defaultTranslations;
  }

  getResource(_resourceKey: string): any {
    return null;
  }

  async getCfgFromBackend(): Promise<IConfigurationModel | undefined> {
    const configuration = await this.configurationService
      .getConfiguration()
      .toPromise();

    if (configuration) {
      DownloadAssetsService.downloadConfigAssets(configuration);
    }

    return configuration;
  }

  async getConfiguration(): Promise<IConfigurationModel | undefined> {
    const configurationFromCache = await StorageManager.getValue(
      StorageKey.configuration,
    );
    if (configurationFromCache) {
      try {
        const configurationVersion = await this.configurationService
          .getCurrentConfigVersion()
          .toPromise();

        if (configurationFromCache.VersionNumber === configurationVersion) {
          return configurationFromCache;
        }
      } catch (error) {
        // continue
      }
    }

    const config = await this.getCfgFromBackend();

    if (config) {
      // Set application config
      config.Application = {
        Notification: {
          Enabled: true,
        },
      };
    }

    return config;
  }

  async health(): Promise<any> {
    return this.httpClient.get("/health");
  }

  async getConfigurationOffline(): Promise<IConfigurationModel | undefined> {
    const configurationFromCache = await StorageManager.getValue(
      StorageKey.configuration,
    );
    if (configurationFromCache) {
      return configurationFromCache;
    }
    return undefined;
  }

  async getScreenConfiguration(
    _screenType: ScreenType,
    _screenId?: number,
  ): Promise<IScreenModel | undefined> {
    return Promise.resolve(undefined);
  }

  async getMedia(options: IMediaOptionsModel): Promise<IMediaModel> {
    return this.mediaService
      .getMedia(ModelsMapperHelper.toMediaOptionsModel(options))
      .toPromise();
  }

  async getMediaPlayInfo(
    options: IMediaPlayInfoOptionsModel,
  ): Promise<IMediaPlayInfoModel> {
    return this.mediaService
      .getMediaPlayInfo(ModelsMapperHelper.toMediaPlayInfoOptionsModel(options))
      .toPromise();
  }

  async searchMedia(
    filter: IMediaSearchFilterModel,
  ): Promise<IMediaSearchStateModel> {
    return this.mediaService.searchMedia(filter).toPromise();
  }

  async getMediaList(
    options: IMediaListOptionsModel,
  ): Promise<IMediaListModel> {
    return this.mediaService
      .getMediaList(ModelsMapperHelper.toMediaListOptionsModel(options))
      .toPromise();
  }

  async getMediaCategories(): Promise<IMediaCategoryListModel> {
    return this.mediaService.getMediaCategories().toPromise();
  }

  // TODO Check if needed
  async getMediaStatistics(
    _options: IMediaStatisticsOptionsModel,
  ): Promise<any> {
    return Promise.reject("Not implemented");
  }

  async getEpgDays(): Promise<EpgDay[]> {
    return [];
  }

  async selectMediaPurchaseOffers(
    mediaId: Identifier,
  ): Promise<IMediaPurchaseOfferModel[]> {
    return this.mediaService.selectMediaPurchaseOffers(+mediaId).toPromise();
  }

  async setProgress(data: IMediaPlaybackProgressModel): Promise<void> {
    const userAssetProperties = await StorageHelper.getUserAssetsProperties();
    const assetProp = userAssetProperties.find(
      (item) => item.AssetId == +data.MediaId,
    );

    const payload = <IUserAssetPropertiesModel>{
      AssetId: +data.MediaId,
      IsFavorite: assetProp?.IsFavorite,
      Timestamp: {
        Hour: Math.floor(data.ProgressInSeconds / 3600),
        Minute: Math.floor((data.ProgressInSeconds % 3600) / 60),
        Second: Math.floor(data.ProgressInSeconds % 60),
      },
    };

    if (data.Status) {
      payload.Status = data.Status;
    }

    const updatedPodcast: IDownloadSource = {
      media: {
        Id: +data.MediaId,
      },
      mediaPlayInfo: {
        Timestamp: {
          Hour: Math.floor(data.ProgressInSeconds / 3600),
          Minute: Math.floor((data.ProgressInSeconds % 3600) / 60),
          Second: Math.floor(data.ProgressInSeconds % 60),
        },
      },
    };

    const isAnonymous = await AuthorizationHelper.isAnonymous();

    if (isAnonymous) {
      return StorageHelper.updateUserAssetsProperties(payload);
    }

    return this.userMediaPropertiesService
      .setProgress(payload)
      .toPromise()
      .then(() => {
        StorageHelper.updateUserAssetsProperties(payload);
        if (data.MediaType === MediaType.Podcast) {
          dispatch(OfflineStore.Actions.updatePodcast(updatedPodcast));
        }
      });
  }

  async getUserAssetsProperties(): Promise<IUserAssetPropertiesModel[]> {
    return this.userMediaPropertiesService.select().toPromise();
  }

  isOnMyList(mediaId: Identifier): Promise<boolean> {
    return StorageHelper.isOnMyList(mediaId);
  }

  addToMyList(mediaId: Identifier): Promise<void> {
    return AuthorizationHelper.isAnonymous().then((isAnonymous) => {
      if (isAnonymous) {
        return StorageHelper.addToMyList(mediaId);
      } else {
        return this.userMediaPropertiesService
          .addToFavourites(+mediaId)
          .toPromise()
          .then(() => StorageHelper.addToMyList(mediaId));
      }
    });
  }

  removeFromMyList(mediaId: Identifier): Promise<void> {
    return AuthorizationHelper.isAnonymous().then((isAnonymous) => {
      if (isAnonymous) {
        return StorageHelper.removeFromMyList(mediaId);
      } else {
        return this.userMediaPropertiesService
          .removeFromFavourites(+mediaId)
          .toPromise()
          .then(() => StorageHelper.removeFromMyList(mediaId));
      }
    });
  }

  getMyListIds(): Promise<Identifier[]> {
    return StorageHelper.getMyListIds();
  }

  async buyMedia(
    data: IMediaPaymentRequestModel,
  ): Promise<IMediaPaymentResponseModel> {
    return this.mediaService
      .buy(ModelsMapperHelper.toMediaPaymentRequestModel(data))
      .toPromise();
  }

  async getProducts(): Promise<IUserProductModel[]> {
    return this.userService.getProducts().toPromise();
  }

  async searchMediaInMedia(
    filter: IMediaSearchMediaInMediaFilterModel,
  ): Promise<IMediaListModel> {
    return this.mediaService
      .searchMediaInMedia(
        ModelsMapperHelper.toMediaSearchMediaInMediaFilterModel(filter),
      )
      .toPromise();
  }

  async syncUser(): Promise<IUserInfoModel | null> {
    return await StorageHelper.getUser();
  }

  async signIn(data: IAuthRequestModel): Promise<IAuthResponseModel> {
    return this.authService.signIn(data).toPromise();
  }

  async signOut(device: IUserDeviceModel): Promise<void> {
    return this.authService.signOut(device).toPromise();
  }

  async forgotPassword(data: IForgotPasswordModel): Promise<boolean> {
    return this.registerService.forgotPassword(data).toPromise();
  }

  async resetForgotPassword(data: IResetForgotPasswordModel): Promise<boolean> {
    return this.registerService.resetForgotPassword(data).toPromise();
  }

  async registerEmail(data: IRegisterRequestEmailModel): Promise<boolean> {
    return this.registerService.registerEmail(data).toPromise();
  }

  async registerConfirmEmail(
    data: IRegisterConfirmEmailModel,
  ): Promise<IAuthResponseModel> {
    return this.registerService.registerConfirmEmail(data).toPromise();
  }

  async registerConfirmAccount(
    data: IConfirmAccountWithPasswordModel,
  ): Promise<IAuthResponseModel> {
    return this.registerService
      .registerConfirmAccountWithPassword(data)
      .toPromise();
  }

  async resendConfirmationEmailByUser(
    data: IResendConfirmationByUserModel,
  ): Promise<void> {
    return this.userService.resendConfirmationEmailByUser(data).toPromise();
  }

  async refreshToken(
    refreshToken: string,
    device: IUserDeviceModel,
  ): Promise<IAuthResponseModel> {
    return this.authService.refreshToken(refreshToken, device).toPromise();
  }

  async changePassword(
    data: IChangePasswordModel,
  ): Promise<IAuthResponseModel> {
    return this.authService.changePassword(data).toPromise();
  }

  async resetPassword(data: IResetPasswordModel): Promise<boolean> {
    return this.registerService.resetPassword(data).toPromise();
  }

  async validateConfirmationToken(token: string): Promise<void> {
    return this.registerService.validateConfirmationToken(token).toPromise();
  }
  // LOGIN CODE
  async linkLoginCode(data: ILoginCodeModel): Promise<ILoginCodeModel> {
    return this.authService.linkLoginCode(data).toPromise();
  }
  async getLoginCode(): Promise<IAuthCodeRequestModel> {
    return this.authService.getLoginCode().toPromise();
  }

  async verifyLogin(data: IAuthVerifyLoginModel): Promise<IAuthResponseModel> {
    return this.authService.verifyLogin(data).toPromise();
  }

  // USER
  async getProfile(options: IUserRequestOptionsModel): Promise<IUserModel> {
    return this.userService.getProfile(options).toPromise();
  }

  async updateProfile(data: IUserModel): Promise<IUserModel> {
    return this.userService.updateProfile(data).toPromise();
  }

  async deleteAccount(data: IUserDeleteAccountRequestModel): Promise<void> {
    return this.userService.deleteAccount(data).toPromise();
  }

  async browseUsers(filter: IUsersSearchFilterModel): Promise<IUsersListModel> {
    return this.userService.browseUsers(filter).toPromise();
  }

  async getUserWallet(): Promise<IUserWalletModel[]> {
    return this.userService.getUserWallet().toPromise();
  }
  async getUserSettings(): Promise<IUserSettingsModel> {
    return this.userSettingsService.get().toPromise();
  }

  async updateUserSettings(
    data: IUserSettingsModel,
  ): Promise<IUserSettingsModel> {
    return this.userSettingsService.update(data).toPromise();
  }

  // USER IN ASSET
  async getUserInAssetRoles(): Promise<IUserInAssetRoleModel[]> {
    return this.userInAssetService.getUserInAssetRoles().toPromise();
  }

  async inviteManyUsers(users: IInviteManyUsersModel): Promise<void> {
    return this.userInAssetService.inviteManyUsers(users).toPromise();
  }

  async searchUsersInAsset(
    filter: IUsersInAssetSearchFilterModel,
  ): Promise<IUsersInAssetListModel> {
    return this.userInAssetService.search(filter).toPromise();
  }

  async removeUserFromAsset(
    user: IUserInAssetModel & { AssetId: number },
  ): Promise<void> {
    return this.userInAssetService.removeUser(user).toPromise();
  }

  async removeManyUsersFromAsset(users: IRemoveManyUsersModel): Promise<void> {
    return this.userInAssetService.removeManyUsers(users).toPromise();
  }

  // CONSENTS
  async selectUserConsents(): Promise<IUserConsentModel[]> {
    return this.userConsentsService.select().toPromise();
  }

  async getUserConsent(url: string): Promise<string> {
    return this.userConsentsService.fetchConsent(url).toPromise();
  }

  async updateUserConsent(data: IUserConsentModel): Promise<IUserConsentModel> {
    return this.userConsentsService.update(data).toPromise();
  }

  async updateUserConsents(
    data: IUserConsentModel[],
  ): Promise<IUserConsentModel[]> {
    return this.userConsentsService.updateCollection(data).toPromise();
  }

  // PAYMENT
  async getPayment(id: number): Promise<IPaymentModel> {
    return this.paymentsService.get(id).toPromise();
  }

  async searchPayment(
    filter: IPaymentSearchFilterModel,
  ): Promise<IPaymentListModel> {
    return this.paymentsService.search(filter).toPromise();
  }

  async getPaymentOptions(): Promise<IPaymentOptionsModel> {
    return this.paymentsService.options().toPromise();
  }

  async getPaymentTypeMappingAndOptions(): Promise<IPaymentTypeMappingAndOptionsModel> {
    return this.paymentsService.paymentTypeMappingAndOptions().toPromise();
  }

  async getByKey(key: string): Promise<IPaymentModel> {
    return this.paymentsService.getByKey(key).toPromise();
  }

  async checkStatusByKey(key: string): Promise<IPaymentStatusModel> {
    return this.paymentsService.checkStatusByKey(key).toPromise();
  }

  async confirmPayment(
    data: IConfirmPaymentRequestModel,
  ): Promise<IPaymentStatusModel> {
    return this.paymentsService.paymentConfirm(data).toPromise();
  }

  // SUBSCRIPTIONS
  async cancelSubscription(
    userSubscriptionId: number,
  ): Promise<IUserAssetPurchasesListModel> {
    return this.userAssetPurchasesService
      .cancelSubscription(userSubscriptionId)
      .toPromise();
  }

  async reactivateSubscription(
    userSubscriptionId: number,
  ): Promise<IUserAssetPurchasesListModel> {
    return this.userAssetPurchasesService
      .reactivateSubscription(userSubscriptionId)
      .toPromise();
  }

  async changeSubscriptionPaymentMethod(
    userSubscriptionId: number,
  ): Promise<IMediaPaymentResponseModel> {
    return this.userAssetPurchasesService
      .changeSubscriptionPaymentMethod(userSubscriptionId)
      .toPromise();
  }

  async searchUserAssetPurchases(
    filter: IUserAssetPurchasesSearchFilterModel,
  ): Promise<IUserAssetPurchasesListModel> {
    return this.userAssetPurchasesService.search(filter).toPromise();
  }

  //ASSETS
  async searchAsset(filter: IAssetSearchFilterModel): Promise<IAssetListModel> {
    return this.assetService.search(filter).toPromise();
  }

  async getUserPurchasesAggregated(): Promise<IUserPurchasesAggregatedModel> {
    return this.userAssetPurchasesService
      .getUserPurchasesAggregated()
      .toPromise();
  }

  async getCryptoCoinPriceList(): Promise<ICryptoCoinPriceListModel[]> {
    return this.cryptoCoinPriceListsService
      .getCryptoCoinPriceList()
      .toPromise();
  }

  async buyCryptoCoins(
    payload: ICoinsPaymentRequestModel,
  ): Promise<ICoinsPaymentResponseModel> {
    return this.cryptoCoinsService.buyCryptoCoins(payload).toPromise();
  }

  async createAsset(
    asset: IInsertAssetRequestModel,
  ): Promise<IInsertAssetResponseModel> {
    return this.assetService.create(asset).toPromise();
  }

  async getAsset(assetId: number): Promise<IAssetModel> {
    return this.assetService.getAsset(assetId).toPromise();
  }

  async updateAsset(asset: IAssetModel): Promise<any> {
    return this.assetService.updateAsset(asset).toPromise();
  }

  async insertAssetImage(image: IAssetImageModel): Promise<IAssetModel> {
    return this.assetService.addAssetImage(image).toPromise();
  }

  async updateAssetImage(image: IAssetImageModel): Promise<IAssetModel> {
    return this.assetService.updateAssetImage(image).toPromise();
  }

  async addAssetContent(
    assetContent: IAssetContentModel,
  ): Promise<IAssetContentModel> {
    return this.assetService.addAssetContent(assetContent).toPromise();
  }

  async updateAssetContent(
    assetContent: IAssetContentModel,
  ): Promise<IAssetContentModel> {
    return this.assetService.updateAssetContent(assetContent).toPromise();
  }

  async requestAssetContentTranscode(
    data: IAssetContentTranscodeRequest,
  ): Promise<IAssetContentModel> {
    return this.assetService.requestContentTranscode(data).toPromise();
  }

  async selectAgeRestriction(): Promise<IAssetAgeRestrictionModel[]> {
    return this.assetService.getAssetAgeRestriction().toPromise();
  }

  async selectCurrency(): Promise<ICurrencyModel[]> {
    return this.currenciesService.select().toPromise();
  }

  async selectPurchasePeriodType(): Promise<IAssetPurchasePeriodTypeModel[]> {
    return this.assetPurchasePeriodTypeService.select().toPromise();
  }

  async saveAssetInAssetCollection(
    data: IAssetInAssetModel[],
  ): Promise<IAssetInAssetModel[]> {
    return this.assetInAssetService.saveCollection(data).toPromise();
  }

  async saveAssetPriceCollection(
    data: IAssetPriceModel[],
  ): Promise<IAssetPriceModel[]> {
    return this.assetPriceService.saveCollection(data).toPromise();
  }

  async getAssetPriceCollection(assetId: number): Promise<IAssetPriceModel> {
    return this.assetPriceService.get(assetId).toPromise();
  }

  async searchAssetPriceCollection(
    filter: IAssetPriceSearchFilterModel,
  ): Promise<IAssetPriceListModel> {
    return this.assetPriceService.search(filter).toPromise();
  }

  async insertCatchup(
    data: ICatchupInsertModel,
  ): Promise<ICatchupInsertResponseModel> {
    return this.assetService.insertCatchup(data).toPromise();
  }

  async searchAssetsInAssets(
    data: IAssetsInAssetSearchFilterModel,
  ): Promise<IAssetInAssetSearchResponseModel> {
    return this.assetInAssetService.searchAssetsInAssets(data).toPromise();
  }

  async selectRatingCategories(): Promise<IUserRatingCategory[]> {
    return this.userPlatformRatingService.selectRatingCategories().toPromise();
  }

  async insertUserRating(data: IUserRatingModel): Promise<void> {
    return this.userPlatformRatingService.insert(data).toPromise();
  }
}
